import { Controller } from 'stimulus';
import latinize from 'latinize';
import { productHeader } from '../shared/product_header';
import { stickyNav } from '../shared/sticky_nav'
import PerfectScrollbar from 'perfect-scrollbar';
import { convertColor } from '../shared/psbc';
export default class ShopController extends Controller {

  connect() {
    const ps = new PerfectScrollbar('.shop_show_category_list', {
      wheelSpeed: 2,
      wheelPropagation: true,
      minScrollbarLength: 20
    });
    convertColor($(this.element).data().color)
    if($(this.element).data().url.length) {
      $.ajax({url: $(this.element).data().url, dataType: 'script'});
    }
    if(localStorage.getItem('edit_product')) {
      setTimeout(() => {
        try {
          let item = JSON.parse(localStorage.getItem('edit_product'))
          localStorage.removeItem('edit_product')
          productHeader($(`[data-item-id="${item.smartId}"]`))
          $.get(`/basket_products/${item.cartId}/edit`)
        } catch (error) {
        }

      }, 500)

    }

    if($('#shop_show').data().product && $(`#shop_product_${$('#shop_show').data().product}`)) {
      let product = $(`#shop_product_${$('#shop_show').data().product}`)
      productHeader(product)
      $.get(`/${product.data().type}/new?item_id=${product.data().itemId}`)
    }
  }

  clickSellable(e) {
    let ele_data = $(e.currentTarget).data()
    if(!ele_data.itemId) { return false; }
    productHeader($(e.currentTarget))
    $.get(`/${ele_data.type}/new?item_id=${ele_data.itemId}&shoppable=${$(this.element).data().shoppable}`)
  }

  show_all() {
    $('.shop_category_item').removeClass('active')
    $(`.shop_category_item[data-category-id="all"]`).addClass('active')
    $('.shop_show_section').toggle(true)
    $('.shop_show_items_container').removeClass('wrapping')
  }

  select_category(e) {
    let id = $(e.currentTarget).data().categoryId
    this.show_all()
    $('#shop_show_search_input').val('')
    $('.show_all_items').removeClass('show_all_items')
    $('hr.shop-show__horizontal-separation').show()
    $('.shop-show__thumbnail-line').show()

    $('.shop_show_items_container').removeClass('wrapping')
    if(id != 'all') {
      $('.shop_shopw_section__see-all').hide()
      $('hr.shop-show__horizontal-separation').hide()
      $('.shop-show__thumbnail-line').hide()
      $('.shop_show_section').hide()

      let section = $(`#shop_section_${id}`)
      section.addClass('show_all_items')
      section.show()
      section.find('.shop_show_see_more').removeClass('d-flex')
      section.find('.shop_show_items_container').addClass('wrapping')
    } else {
      $('.shop_shopw_section__see-all').show()
      $('.shop_show_section').show()
      $('.shop_show_section').filter(function() {
        $(this).toggle($(this).find('.shop_show_item_card:visible').length != 0)
      })
    }
    $('.shop_category_item').removeClass('active')
    $(`.shop_category_item[data-category-id="${id}"]`).addClass('active')
    window.scrollTo(0, 0);
    if($(window).width() > 1200) {
      stickyNav(($(document).scrollTop() - 50) / $(window).height())
    }

  }

  search(e) {
    let value = latinize($('#shop_show_search_input').val().toLowerCase())
    if(value.length > 0) {$('.shop-show__thumbnail-line').hide()
      $('.shop-show__thumbnail-line').hide()
      window.scrollTo(0, 0);
      $('.shop_show_shop_card').hide(200)
      $('.shop_show_item_card').filter(function() {
        $(this).toggle(latinize($(this).data().filter.toLowerCase()).indexOf(value) > -1)
      });
    } else {
      $('.shop_show_shop_card').show()
      this.show_all()
    }

    $('.shop_show_section').filter(function() {
      $(this).toggle($(this).find('.shop_show_item_card:visible').length != 0)
    })
  }

  showHours() {
    let url = `/shops/${$(this.element).data().id}/show_hours`
    if($(this.element).data().type.length) {
      url += `?shop_type=true`
    }
    $.get(url)
  }

  showDescription() {
    let url = `/shops/${$(this.element).data().id}/show_description`
    if($(this.element).data().type.length) {
      url += `?shop_type=true`
    }
    $.get(url)
  }  

  showVitrine(e) {
    let url = `/shops/${$(this.element).data().id}/show_vitrine?vitrine_id=${$(e.currentTarget).data().id}`
    if($(this.element).data().type.length) {
      url += `&shop_type=true`
    }
    $.get(url)    
  }

}
